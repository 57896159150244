import { Route, createBrowserRouter, createRoutesFromElements, Outlet, defer } from "react-router-dom";
import VideoRoom from "./components/VideoRoom";
import Error from "./components/Error";
import WaitingRoom from "./components/WaitingRoom";
import EndCall from "./components/EndCall";
import RequireUser from "./components/UserNameRoute";
import { getAppointmentParticipation } from './api/hermes';

export default createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="" element={
          <div>
            <h1>Welcome to MJG Video</h1>
            <a href="http://localhost:4008/appointment/614228d0c1d4104926402639/participant/614228c9c1d4104926402524/lobby">
              Debug URL: http://localhost:4008/appointment/614228d0c1d4104926402639/participant/614228c9c1d4104926402524/lobby
            </a>
          </div>
        } />
        <Route path="appointment/:appointmentId/participant/:participantId"
          loader={async ({ params }) => {
            const { appointmentId, participantId } = params
            const appointment = await getAppointmentParticipation({ appointmentId, participantId });
            // const appointment = await setTimeout(() => (
            //   {
            //     appointment: {
            //       id: appointmentId,
            //     },
            //     user: {
            //       name: "Mickey Mouse",
            //       id: participantId,
            //     }
            //   }
            // ), 2000)

            return defer({ appointment });
          }}
          errorElement={<div>Error :'(</div>}
          id="appointment"
        >
          <Route path="lobby"
            element={<WaitingRoom />}
          />
          <Route path=""
            element={
              <RequireUser redirectTo="/">
                <VideoRoom />
              </RequireUser>
            }
          />
          <Route path=":sessionId/end"
            element={<EndCall />}
          />
        </Route>
        <Route path="/error" element={<Error />}></Route>
      </Route>
    )
);
