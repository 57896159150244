import logo from "./logo.svg";
import "./App.css";
import router from "router";
import { UserContext } from "./context/UserContext";
import { RouterProvider } from "react-router-dom";

// Theme Configuration
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/styles";
import { useMemo, useState, StrictMode } from "react";

let primary = process.env.REACT_APP_PALETTE_PRIMARY || "#b779ff";
let secondary = process.env.REACT_APP_PALETTE_SECONDARY || "#d6219c";

const theme = () => {
  return createTheme({
    palette: {
      type: "light",
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      bodyBackground: {
        black: "#131415",
      },
      callBackground: {
        main: "#20262D",
      },
      toolbarBackground: {
        main: "#41464D",
      },
      activeButtons: {
        green: "#1C8731",
        red: "#D50F2C",
      },
    },
  });
};

function App() {
  const [user, setUser] = useState({
    videoFilter: {
      filterName: false,
      filterPayload: false,
    },
    defaultSettings: {
      publishAudio: true,
      publishVideo: true,
      audioSource: undefined,
      videoSource: undefined,
      audioOutput: undefined,
    },
  });
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <StrictMode>
      <ThemeProvider theme={theme()}>
        <UserContext.Provider value={userValue}>
          <RouterProvider router={router} />
        </UserContext.Provider>
      </ThemeProvider>
    </StrictMode>
  );
}

export default App;
