import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useParams } from 'react-router';

import { UserContext } from '../../context/UserContext';

const RequireUserName = ({ children, redirectTo }) => {
  const { user } = useContext(UserContext);
  console.log(user)
  return user.userName ? children : <Navigate replace={true} to={redirectTo} />;
};

export default RequireUserName;
