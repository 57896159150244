import axios from 'axios';

let API_URL = process.env.REACT_APP_HERMES_URL;

export const getAppointmentParticipation = async ({ appointmentId, participantId }) => {
  return axios.get(
    `${API_URL}/api/v1/messaging/appointments/${appointmentId}/video?participantId=${participantId}`,
    {
      headers: {
        // Authorization: `Bearer ${token}`,
        'Content-Type': `application/json`,
      },
    }, { withCredentials: true }
  );
};
